import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LOCAL_STORAGE_KEY = 'app_locale';

const FALLBACK_LOCALE = 'en';

export const getBrowserLocale = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || FALLBACK_LOCALE;
}

const setBrowserLocale = (locale: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, locale);
}

type UseLocale = {
  /** the ephemeral locale stored in React state, which defaults to browserLocale unless updated */
  sessionLocale: string;
  /** the persistent locale stored in local storage */
  browserLocale: string;
  /** sets the ephemeral locale stored in React state */
  setSessionLocale: (locale: string) => void;
  /** sets both the persistent local storage locale, and the React state locale */
  setBrowserLocale: (locale: string) => void;
};

/** Utility hook to allow manipulation of two levels of locale preference:
 * 1. "browserLocale" which is a persistent locale string stored in local storage
 * 2. "sessionLocale" which can be overridden on an ephemeral basis, but which defaults to browserLocale
 * 
 * Use this hook in preference to manipulating localStorage directly
 */
const useLocale = () => {
  const [, i18n] = useTranslation();
  const browserLocale = getBrowserLocale();
  const [sessionLocaleState, setSessionLocaleState] = useState(browserLocale);

  const setSessionLocale = useCallback((locale: string) => {
    setSessionLocaleState(locale);
    i18n.changeLanguage(locale);
  }, [setSessionLocaleState, i18n]);

  const setBrowserAndSessionLocale = useCallback((locale: string) => {
    setBrowserLocale(locale);
    setSessionLocale(locale);
  }, [setSessionLocale]);

  const fn: UseLocale = useMemo(() => ({
    sessionLocale: sessionLocaleState,
    browserLocale,
    setSessionLocale,
    setBrowserLocale: setBrowserAndSessionLocale,
  }), [sessionLocaleState, setBrowserAndSessionLocale, setSessionLocale, browserLocale]);

  return fn;
};

export default useLocale;
